import { css } from '@emotion/react';
import { Types } from '@scorenco/core';

import { Wrapper } from '../../primitives';
import { StrapiImage } from '../../strapi';

type ImageSliceProps = {
  slice: Types.ComponentSlicesImage;
};

export const ImageSlice = ({ slice }: ImageSliceProps) => {
  return (
    <Wrapper marginY={5}>
      <StrapiImage
        image={slice.image}
        css={(theme) => css`
          margin: 0 auto;
          max-width: ${theme.breakpoints.values.sm}px;
        `}
      />
    </Wrapper>
  );
};
